import { NMAAHCPropTypes, Theme, useScrollObserver } from "assets";
import { Card, FormattedText, Scroller } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import PropType from "prop-types";
import React, { useRef } from "react";

import * as styles from "./cards-scroll-wrapper.module.scss";

const CardsScrollWrapper = ({
  children,
  text,
  title,
  titleFont,
  isStories,
}) => {
  const ref = useRef();
  const scrollableAreaRef = useRef();

  const scrolledClasses = useScrollObserver(ref);

  const classes = classNames("container-fluid", styles.cards, scrolledClasses, {
    [styles.storiesLayout]: isStories,
  });

  const scrollerWrapperClasses = classNames("col-md-4", styles.centerScrollbar);

  const scrollContentAreaWrapperClasses = classNames(
    [styles.scrollContentArea],
    [styles.staggerCardHeights]
  );

  const displayCards = () => {
    if (isStories) {
      return <div className={styles.contentAreaWrapper}>{children}</div>;
    } else {
      return (
        <div className={styles.contentAreaWrapper}>
          <div
            className={styles.scrollContainer}
            data-testid={"scrollLayoutArea"}
            ref={scrollableAreaRef}
          >
            <div className={scrollContentAreaWrapperClasses}>{children}</div>
          </div>
          <div className={scrollerWrapperClasses}>
            <Scroller ref={scrollableAreaRef} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className={classes} data-testid="cards-scroll-wrapper" ref={ref}>
      <FormattedText
        className={classNames(styles.titleArea, titleFont, styles.center)}
        outerElement={<h2 />}
        text={title}
        deepLink
      />
      <FormattedText className={styles.center} text={text} />
      {displayCards()}
    </div>
  );
};

CardsScrollWrapper.propTypes = {
  children: PropType.node,
  isStories: PropType.bool,
  text: PropType.string,
  title: PropType.string,
  titleFont: NMAAHCPropTypes.FontType,
};

CardsScrollWrapper.defaultProps = {
  isStories: false,
};

const CardsFragment = graphql`
  fragment CardsFragment on CraftAPI_componentList_cards_BlockType {
    id
    cardsTitle
    text
    containedCards {
      ... on CraftAPI_containedCards_BlockType {
        id
        cardTitle
        subtitle
        cardTheme
        pageLink {
          id
          uri
        }
        externalLink
        caption
        image {
          ... on CraftAPI_image_Asset {
            altText
            imageFile {
              childImageSharp {
                gatsbyImageData(width: 363)
              }
            }
            url
          }
        }
      }
    }
  }
`;

/**
 * Converts the provided card data into a cards component
 *
 * @param cardsData  the GraphQL response data
 * @returns         the cards component
 */
const convert = (cardsData) => {
  const cards = cardsData?.containedCards?.map((cardData) => {
    return (
      <Card
        bannerColor={cardsData.bannerColor}
        bannerIcon={cardsData.bannerIcon}
        bannerLabel={cardsData.bannerLabel}
        caption={cardData.caption}
        externalLink={cardData.externalLink}
        image={cardData.image?.[0]}
        key={cardData.id}
        pageLink={cardData.pageLink?.[0]}
        storyType={cardData.storyType}
        subtitle={cardsData?.layout === "stores" ? true : false}
        tags={["tag1", "tag2"]}
        theme={cardData?.cardTheme ?? Theme.White}
        title={cardData.cardTitle}
      />
    );
  });

  return (
    <CardsScrollWrapper
      key={cardsData.id}
      layout={cardsData.layout}
      text={cardsData.text}
      title={cardsData.cardsTitle}
      titleFont={cardsData.titleFont}
    >
      {cards}
    </CardsScrollWrapper>
  );
};

export { CardsFragment, convert, CardsScrollWrapper as default };
