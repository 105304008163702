import { setTagList, Theme } from "assets";
import { Card } from "atoms";
import { CardsScrollWrapper } from "molecules";
import { FilteredContent } from "organisms";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import * as styles from "./stories-landing-page-content.module.scss";

const StoriesLandingPageContent = ({ storyData, storyTagData }) => {
  const [filterTag, setFilterTag] = useState(null);
  const [filterTimePeriod, setFilterTimePeriod] = useState(null);
  const [randomSeed, setRandomSeed] = useState(Math.random());
  const [showTags, setShowTags] = useState(false);
  if (!randomSeed) setRandomSeed(Math.random());

  const setStoryTags = (stories) => {
    stories?.map((story) => {
      story.tags = [];
      story.topicTag?.map((tag) => {
        story.tags.push(tag.title);
      });
      if (!Array.isArray(story.displayedTag))
        story.displayedTag = [
          story.tags[Math.floor(randomSeed * story.tags.length)],
        ];
      if (Array.isArray(story.timePeriod))
        story.timePeriod = story.timePeriod[0]?.title;
    });
  };

  const assignCardData = (cardData, featured) => {
    const filteredTag =
      (filterTag || filterTimePeriod) && !featured
        ? [filterTag || filterTimePeriod]
        : null;
    if (cardData.children) {
      const pageLink = {
        id: cardData.children[0]?.id,
        uri: cardData.children[0]?.uri,
      };
      return (
        <Card
          caption={
            cardData.shortDescription || cardData.children[0]?.shortDescription
          }
          horizontalLayout={featured}
          image={
            cardData.coverImage?.[0] || cardData.children[0]?.coverImage?.[0]
          }
          imageObjectFit={
            cardData.coverImage?.[0] ? cardData.coverImageCropStyle : null
          }
          imageObjectPosition={
            cardData.coverImage?.[0]
              ? cardData.coverImagePositionOverride
              : null
          }
          key={cardData.id}
          pageLink={pageLink}
          showTags={showTags}
          storyType={cardData.storyType}
          tags={filteredTag || cardData.displayedTag}
          theme={Theme.Black}
          title={cardData.title}
        />
      );
    } else {
      const pageLink = { id: cardData.id, uri: cardData.uri };
      return (
        <Card
          caption={cardData.shortDescription}
          horizontalLayout={featured}
          image={cardData.coverImage?.[0]}
          imageObjectFit={cardData.coverImageCropStyle}
          imageObjectPosition={cardData.coverImagePositionOverride}
          key={cardData.id}
          pageLink={pageLink}
          showTags={showTags}
          storyType={cardData.storyType}
          tags={filteredTag || cardData.displayedTag}
          theme={Theme.Black}
          title={cardData.title}
        />
      );
    }
  };

  const cardsData = storyData?.children;
  setStoryTags(cardsData);
  const displayedCards = cardsData
    ?.filter(
      (cardData) =>
        (!filterTag && !filterTimePeriod) ||
        (filterTag && cardData.tags?.includes(filterTag)) ||
        (filterTimePeriod && cardData.timePeriod === filterTimePeriod)
    )
    .map((cardData) => {
      return assignCardData(cardData, false);
    });

  const featuredStory = storyData?.featuredStory?.[0];
  if (featuredStory) {
    featuredStory.tags = [];
    featuredStory.topicTag?.map((tag) => {
      featuredStory.tags.push(tag.title);
    });
    if (!Array.isArray(featuredStory.displayedTag))
      featuredStory.displayedTag = [
        featuredStory.tags[Math.floor(randomSeed * featuredStory.tags.length)],
      ];
  }
  const featuredStoryCard = featuredStory
    ? assignCardData(featuredStory, true)
    : null;

  const tagList = setTagList("topic", storyTagData, storyData);
  const timePeriodList = setTagList("timePeriods", storyTagData, storyData);

  useEffect(() => {
    setShowTags(true);
  });

  return (
    <div
      className={`row ${styles.storiesContainer}`}
      data-testid="stories-landing-page"
    >
      <div className={`col-xs-12 ${styles.columnConstraint}`}>
        {featuredStoryCard && (
          <div className={`container-fluid ${styles.featuredStory}`}>
            <h2>Featured Story</h2>
            {featuredStoryCard}
          </div>
        )}
        <div className={`container-fluid ${styles.storyTitle}`}>
          <h2>All Stories</h2>
        </div>
        <FilteredContent
          contentLength={displayedCards?.length}
          contentType={"stories"}
          filterTagList1={["Topics", tagList]}
          filterTagList2={["Time Periods", timePeriodList]}
          setParentTag1={setFilterTag}
          setParentTag2={setFilterTimePeriod}
        >
          <CardsScrollWrapper key={storyData?.id} isStories>
            {displayedCards}
          </CardsScrollWrapper>
        </FilteredContent>
      </div>
    </div>
  );
};

StoriesLandingPageContent.propTypes = {
  storyData: PropTypes.object,
  storyTagData: PropTypes.array,
};

StoriesLandingPageContent.defaultProps = {};

export default StoriesLandingPageContent;
