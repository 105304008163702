import { GlowBorder, NMAAHCPropTypes, Theme, ThemeContext } from "assets";
import { getFontSizeStyling } from "assets/js/get-font-size-styling/get-font-size-styling";
import { BackgroundImageWrapper, FormattedText, Listen } from "atoms";
import classNames from "classnames";
import { ExpandableImage } from "molecules";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import * as styles from "./exhibit-hero.module.scss";

const ExhibitHero = ({
  audioAsset,
  audioClosedCaption,
  backgroundImageUrl,
  fontColor,
  heroBackgroundAlignment,
  image,
  imageCaption,
  imageObjectFit,
  imageObjectPosition,
  formattedTitleOverride,
  titleSize,
  subtitle,
  longDescription,
  title,
  titleColor,
  presentationType,
  titleDate,
  theme,
}) => {
  const { pillarTitleColor, fontType } = useContext(ThemeContext);
  const showGlowBorder = theme === Theme.Afrofuturism;

  const titleClassName = classNames(
    fontType,
    {
      [styles.unformattedThemedFonts]:
        presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED &&
        formattedTitleOverride == null,
      [styles.themedFonts]:
        presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
      [styles.nuba]: fontType == NMAAHCPropTypes.FONT_NUBA,
      [styles.afroGlow]: theme === Theme.Afrofuturism,
    },
    getFontSizeStyling(titleSize)
  );

  const subtitleClassName = classNames(styles.subtitle, fontType, {
    [styles.unformattedThemedFonts]:
      presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED &&
      formattedTitleOverride == null,
    [styles.themedFonts]:
      presentationType == NMAAHCPropTypes.EXHIBIT_TYPE_THEMED,
    [styles.nuba]: fontType == NMAAHCPropTypes.FONT_NUBA,
  });

  const textContainerClassName = classNames(
    "col-xs-12",
    [styles.textContainer],
    {
      [styles.gridText]: image,
      [styles.noText]: !longDescription && image,
    }
  );

  return (
    <BackgroundImageWrapper
      backgroundImageUrl={backgroundImageUrl}
      backgroundPosition={heroBackgroundAlignment}
      className={styles.hero}
      fontColor={fontColor}
    >
      <div
        className="col-lg-10 col-lg-offset-1 container-fluid"
        data-testid="exhibit-hero"
      >
        {audioAsset?.title && audioAsset?.url && (
          <Listen
            audioAsset={audioAsset}
            audioClosedCaption={audioClosedCaption}
            theme={Theme.Subtle}
          />
        )}
        <div className={classNames("row", { [styles.grid]: image })}>
          <div
            className={classNames(
              textContainerClassName,
              styles.titlesContainer,
              { ...GlowBorder.addBorder(showGlowBorder, theme) }
            )}
          >
            <FormattedText
              className={titleClassName}
              outerElement={<h1 />}
              style={{ color: titleColor || pillarTitleColor || "inherit" }}
              text={formattedTitleOverride || title}
            />
            {titleDate && (
              <p className={styles.titleDate} data-testid="title-date">
                {titleDate}
              </p>
            )}
            <FormattedText className={subtitleClassName} text={subtitle} />
          </div>
          {image && (
            <div className={classNames("col-xs-12", [styles.imageContainer])}>
              <ExpandableImage
                componentSpacing={false}
                image={image}
                imageCaption={imageCaption}
                imageObjectFit={imageObjectFit}
                imageObjectPosition={imageObjectPosition}
                modalCaptionOnly
              />
            </div>
          )}
          <div className={textContainerClassName}>
            <FormattedText
              className={classNames(styles.textClass, "large-font")}
              text={longDescription}
              theme={fontColor === "light" ? "dark" : theme}
            />
          </div>
        </div>
      </div>
    </BackgroundImageWrapper>
  );
};

ExhibitHero.propTypes = {
  audioAsset: NMAAHCPropTypes.Audio,
  audioClosedCaption: PropTypes.string,
  formattedTitleOverride: PropTypes.string,
  heroBackgroundAlignment: PropTypes.string,
  image: NMAAHCPropTypes.Image,
  imageCaption: PropTypes.string,
  imageObjectFit: NMAAHCPropTypes.ImageObjectFit,
  imageObjectPosition: PropTypes.string,
  longDescription: PropTypes.string,
  presentationType: NMAAHCPropTypes.ExhibitType,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleColor: PropTypes.string,
  titleDate: PropTypes.string,
  titleSize: NMAAHCPropTypes.TitleSize,
  ...Theme.PropType,
};

ExhibitHero.defaultProps = {
  theme: Theme.Light,
};

export default ExhibitHero;
