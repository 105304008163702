import { NMAAHCPropTypes, ThemeContext } from "assets";
import { BackgroundImageWrapper, FormattedText } from "atoms";
import classNames from "classnames";
import { graphql } from "gatsby";
import { CarouselWrapper } from "molecules";
import { Quote } from "organisms";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import * as styles from "./quote-gallery.module.scss";

const QuoteGallery = ({
  galleryQuotes,
  backgroundCover,
  backgroundImageUrl,
  fontColor,
  textAlignment,
  title,
}) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { fontType } = useContext(ThemeContext);

  return (
    <BackgroundImageWrapper
      backgroundCover={backgroundCover}
      backgroundImageUrl={backgroundImageUrl}
      className={styles.quoteGalleryContainer}
      fontColor={fontColor}
      fullWidthComponent
    >
      {title && (
        <div className="container-fluid">
          <div className="row center-xs">
            <div className="col-xs">
              <FormattedText
                className={classNames(fontType)}
                data-testid={"gallery-title"}
                outerElement={<h2 />}
                text={title}
                deepLink
              />
            </div>
          </div>
        </div>
      )}
      <CarouselWrapper
        fontColor={fontColor}
        onSelectedIndex={(newIndex) => setSelectedIndex(newIndex)}
        overrideScrollTo={selectedIndex}
        startIndex={0}
        textAlignment={textAlignment}
        showNavDots
      >
        {galleryQuotes.map((currentQuote, i) => {
          let quote = currentQuote.referenceQuote[0];
          let image = currentQuote?.image?.[0];
          const emblaElementContainerClass = classNames({
            embla__slide: true,
            [styles.carouselSlide]: true,
            [styles.blur]: selectedIndex !== i ? styles.blur : "",
          });

          return (
            <div
              className={emblaElementContainerClass}
              data-testid={"quote-slide"}
              key={i}
              onFocus={() => setSelectedIndex(i)}
            >
              <div className={styles.quoteContainer}>
                <Quote
                  audioAsset={quote.audioAsset?.[0]}
                  author={quote.quoteAuthor}
                  date={quote.quoteYear}
                  fontColor={fontColor}
                  imageObjectPosition={
                    currentQuote.imagePositionOverride || undefined
                  }
                  quote={quote.transcription}
                  quoteImage={image}
                  textAlignment={textAlignment}
                  withComponentSpacing={false}
                  fullWidth
                />
              </div>
            </div>
          );
        })}
      </CarouselWrapper>
    </BackgroundImageWrapper>
  );
};

QuoteGallery.propTypes = {
  backgroundCover: PropTypes.bool,
  backgroundImageUrl: PropTypes.string,
  fontColor: PropTypes.string,
  galleryQuotes: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.arrayOf(NMAAHCPropTypes.Image),
    })
  ).isRequired,
  textAlignment: PropTypes.oneOf(["center", "left"]),
  title: PropTypes.string,
};

QuoteGallery.defaultProps = {
  textAlignment: "center",
};

const QuoteGalleryFragment = graphql`
  fragment QuoteGalleryFragment on CraftAPI_componentList_quoteGallery_BlockType {
    id
    backgroundCover
    backgroundImage {
      url
    }
    fontColor
    textAlignment
    quoteGalleryTitle
    galleryQuotes {
      ... on CraftAPI_galleryQuotes_BlockType {
        id
        image {
          ... on CraftAPI_image_Asset {
            ...ImageMetadataFragment
          }
        }
        imagePositionOverride
        referenceQuote {
          ... on CraftAPI_object_quote_Entry {
            audioAsset {
              ...AudioAssetFragment
            }
            title
            quoteAuthor
            quoteYear
            transcription
          }
        }
      }
    }
  }
`;

const convert = (quoteGalleryData) => {
  return (
    <QuoteGallery
      backgroundCover={quoteGalleryData?.backgroundCover}
      backgroundImageUrl={quoteGalleryData?.backgroundImage?.[0]?.url}
      fontColor={quoteGalleryData?.fontColor}
      galleryQuotes={quoteGalleryData?.galleryQuotes}
      key={quoteGalleryData.id}
      textAlignment={quoteGalleryData.textAlignment || undefined}
      title={quoteGalleryData?.quoteGalleryTitle}
    />
  );
};

export { convert, QuoteGallery as default, QuoteGalleryFragment };
